<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="close">
          <div class="modal-container">
              <div class="modal-header">
              <img class="closer" :src="require(`@/assets/images/cross.svg`)" @click="close">
                <slot name="header">
                  <img :src="require(`@/assets/images/${filename}.svg`)"  class="logocard">
                  <div class="review__name">{{ reviewInfo.name }}</div>
                  <div class="review__orgame">{{ reviewInfo.orgName }}</div>
                  <div class="review__status">{{ reviewInfo.status }}</div>
                </slot>
              </div>

              <div class="modal-body">
                <slot name="body">
                  <div class="review__cardtext">
                    <img src="@/assets/images/marks.svg" class="marks">
                    <div class="review__text">{{ reviewInfo.text }}</div>
                  </div>
                </slot>
              </div>

              <div class="modal-footer">
                <slot name="footer">
                  <div class="review__cardinfo">
                    <img src="@/assets/images/star.svg" class="stars">
                    <div class="review__rating">{{ reviewInfo.rating }}</div>
                    <div class="review__date"> {{ reviewInfo.date | formatDate }}</div>
                  </div>
                </slot>
              </div>

            </div>
          </div>
        </div>

  </transition>
</template>

<script>

export default {
  name: "vModal",
  props: {
    reviewInfo: {
      type: Object,
      required: false,
      default: () => {
      },
    }
    },
  computed: {
    filename() {
      return this.reviewInfo.gender === "man" ? 'm-03' : 'w-03'
    },
  },
  mounted() {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  methods: {
    close() {
      this.$emit('close')
    },
  }
  }
</script>

<style lang="scss"  scoped>
@import "src/assets/styles/mainWrapper.sass";
@import "src/assets/styles/vModal.sass";

.review {
  width: 525px;
  height: 310px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 6px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  padding: 25px 28px 12px;

  &__card {
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__status {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 10px;
    position: relative;
    left: 140px;
    top: -93px;

    @media (max-width: 768px) and (orientation: portrait) {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      position: relative;
      left: 70px;
      top: -55px;
    }
  }

  &__orgame {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    left: 140px;
    top: -90px;

    @media (max-width: 768px) and (orientation: portrait) {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      position: relative;
      left: 70px;
      top: -57px;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    left: 140px;
    top: -90px;
    max-width: 600px;

    @media (max-width: 768px) and (orientation: portrait) {
      max-width: 200px;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      position: relative;
      left: 70px;
      top: -55px;
    }

  }

  &__cardtext {
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
    height: 140px;
    width: 378px;
    justify-content: right;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    word-wrap: break-word;
    position: absolute;
    left: 77px;
    top: 150px;
    width: 800px;

    @media (max-width: 768px) and (orientation: portrait) {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      width: 220px;
      left: 14%;
      top: 101px
    }
  }

  &__cardinfo {
    margin-top: 15px;
    display: block;
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__rating {
    display: inline;
    font-weight: 400;
    font-size: 25px;
    color: #C4C4C4;
    position: absolute;
    left: 35px;
    bottom: -14%;

    @media (max-width: 768px) and (orientation: portrait) {
      bottom: -197%;
      left: 26px;
      font-size: 17px;
    }
  }

  &__date {
    margin-left: 725px;
    width: 100%;
    display: inline;
    justify-content: space-between;
    color: #C4C4C4;
    font-size: 20px;

    @media (max-width: 768px) and (orientation: portrait) {
      position: absolute;
      margin-left: 0;
      left: 170px;
      bottom: -96%;
      top: 27px;
      width: 100px;
      font-size: 14px;
      color: #C4C4C4;
    }
  }
}

.logocard {
  @media (max-width: 768px) and (orientation: portrait) {
    width: 65px;
    height: 67px;
  }
}
.stars {
  position: absolute;
  @media (max-width: 768px) and (orientation: portrait) {
    bottom: -185%;
    height: 20px;
    width: 20px;
  }
}

.marks {
  position: absolute;
  left: 4%;
  bottom: 66%;

  @media (max-width: 768px) and (orientation: portrait) {
    height: 13px;
    width: 15px;
    top: 100px;
    left: 7%;
  }
}

.closer {
  cursor: pointer;
  position: absolute;
  left: 820px;
  top: 30px;

  @media (max-width: 768px) and (orientation: portrait) {
    width: 11px;
    height: 11px;
    position: absolute;
    left: 260px;
    top: 10px;
  }
}
</style>
