<template>
  <div class="review" @click="emitClick">
    <div class="review__card" >
      <img :src="require(`@/assets/images/${filename}.svg`)" class="logocard"> <!--fileName= m-02 fileName=w-02-->
      <div class="review__name">{{ reviewInfo.name }}</div>
      <div class="review__orgame" hidden>{{ reviewInfo.orgName }}</div>
      <div class="review__status">{{ reviewInfo.status }}</div>
    </div>
    <div class="review__cardtext">
      <img src="@/assets/images/marks.svg" class="marks">
      <div class="review__text-card">{{ reviewInfo.text }}</div>
    </div>
    <div class="review__cardinfo">
      <img src="@/assets/images/star.svg" class="stars">
      <div class="review__rating">{{ reviewInfo.rating }}</div>
      <div class="review__date"> {{ reviewInfo.date | formatDate }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CardReviews",
  props: {
    reviewInfo: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    filename() {
      return this.reviewInfo.gender === "man" ? 'm-02' : 'w-02'
    },
  },
  methods: {
    emitClick() {
      this.$emit('click-inside', this.reviewInfo)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/variables";

.review {
  width: 525px;
  height: 310px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow:  0px 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  cursor: pointer;
  padding: 25px 28px 12px;

  @media (max-width: $mobile-version) and (orientation: portrait) {
    height: 153px;
    width: 255px;
    border-radius: 3px;
    padding: 7px 8px 17px;
    box-shadow:  0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    box-shadow: $boxShadowHoverLight;
  }
  &__card {
    height: 80px;
    display: block;
    align-items: center;
    margin-bottom: 18px;
    font-size: 32px;
    font-weight: 700;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 700;
      height: 40px;
    }
  }

  &__status {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    top: 20px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      width: 225px;
      top: 0;
    }
  }

  &__orgame {
    font-size: 20px;
    font-weight: 500;
    position: relative;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
    }
  }

  &__name {
    font-size: 20px;
    font-weight: 500;
    position: relative;
    top: 20px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      top: 0;
    }
  }

  &__cardinfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
  }

  &__cardtext {
    overflow-y: hidden;
    @media (max-width: $mobile-version) and (orientation: portrait) {
    height: 60px;
    }
  }

  &__text-card {
    height: 140px;
    font-size: 16px;
    line-height: 20px;
    //word-wrap: break-word;
    //overflow-y: hidden;
    //overflow-x:hidden;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      height: 55px;
    }
  }

  &__rating {
    display: inline;
    font-weight: 400;
    font-size: 25px;
    color: #C4C4C4;
    position: relative;
    top: 4px;
    left: -153px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      position: relative;
      top:14px;
      left: -75px;
    }
  }

  &__date {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    position: relative;
    top: 11px;
    color: #C4C4C4;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      position: relative;
      top:16px;
      color: #C4C4C4;
    }
  }

  .marks {
    float: left;
    margin-right: 10px;
    position: relative;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 12px;
      width: 13px;
    }
  }

  .logocard {
    float: left;
    margin-right: 10px;

    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 36px;
      width: 35px;
      margin-right: 5px;
    }
  }

  .stars {
    @media (max-width: $mobile-version) and (orientation: portrait) {
      height: 15px;
      width: 14px;
      position: relative;
      top:13px;
    }
  }
}
</style>
