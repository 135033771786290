import request from "@/services/request";

async function putReview(review) {
    try {
        const res = await request({
            endpoint: `/api/review/set`,
            body: review,
            method: 'PUT',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('review');
        }
    } catch (e) {
        console.error(e)
        throw new Error('review');
    }
}
async function deleteReview(id) {
    try {
        const res = await request({
            endpoint: `/api/review/del?id=${id}`,
            method: 'delete',
        });
        if (res.status === 200) {
            return res.data;
        } else {
            throw new Error('review');
        }
    } catch (e) {
        console.error(e)
        throw new Error('review');
    }
}

async function getReviews() {
    try {
        const res = await request({
            endpoint: `/api/review/getAll`,
            method: 'GET',
        }); // создается запрос на сервер и он возвращает тебе отвеь
        if (res.status === 200) {
            return res.data;// если запрос выполнен успещно вернет результат
        } else {
            throw new Error('reviews');
        }
    } catch (e) {
        console.error(e)
        throw new Error('reviews');
    }
}

async function getTwoReviews() {
    try {
        const res = await request({
            endpoint: `/api/review/get2`,
            method: 'GET',
        }); // создается запрос на сервер и он возвращает тебе отвеь
        if (res.status === 200) {
            return res.data;// если запрос выполнен успещно вернет результат
        } else {
            throw new Error('reviews');
        }
    } catch (e) {
        console.error(e)
        throw new Error('reviews');
    }
}


export  {putReview, getReviews, getTwoReviews, deleteReview }
